
import { Component, Vue } from 'vue-property-decorator'
import {
    apiGameHelpCategoryLists,
    apiGameHelpAdd,
    apiGameHelpEdit,
    apiGameHelpDetail
} from '@/api/setting'
import MaterialSelect from '@/components/material-select/index.vue'
import LsEditor from '@/components/editor.vue'
import { PageMode } from '@/utils/type'

@Component({
    components: {
        LsEditor,
        MaterialSelect
    }
})
export default class GameHelpEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色

    // 分类列表
    categoryList: Array<object> = []

    // 表单数据
    form: any = {
        aid: '', // appid
        id: 0, // 当前编辑用户的身份ID
        title: '', // 标题
        cid: '', // 分类id
        synopsis: '', // 简介
        image: '', // 封面图片
        content: '', // 内容
        sort: 0, // 排序值
        is_show: 1 // 显示: 0-隐藏 1-显示(默认)
    }

    // 表单校验
    rules: object = {
        title: [
            {
                required: true,
                message: '请输入文章标题',
                trigger: 'blur'
            }
        ],
        cid: [
            {
                required: true,
                message: '请选择文章分类',
                trigger: 'change'
            }
        ],
        // synopsis: [{
        // 	required: true,
        // 	message: '请输入文章简介',
        // 	trigger: 'blur'
        // }],
        // sort: [{
        // 	required: true,
        // 	message: '请输入排序',
        // 	trigger: 'blur'
        // }],
        content: [
            {
                required: true,
                message: '请输入文章标题',
                trigger: 'blur'
            }
        ]
    }

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): void => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleGameHelpAdd()
                case PageMode.EDIT:
                    return this.handleGameHelpEdit()
            }
        })
    }

    // 添加帮助文章
    handleGameHelpAdd() {
        apiGameHelpAdd(this.form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑帮助文章
    handleGameHelpEdit() {
        apiGameHelpEdit(this.form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 初始化表单数据: 编辑
    initGameHelpEdit() {
        apiGameHelpDetail({
            id: this.form.id,
            aid: this.form.aid
        }).then(res => {
            Object.keys(res).map(item => {
                this.$set(this.form, item, res[item])
            })
        })
    }

    // 初始化文章分类数据
    initCategoryLists() {
        apiGameHelpCategoryLists({}).then(res => {
            console.log('res', res)
            this.categoryList = res.lists
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        this.form.aid = query.aid || ''
        if (query.mode) {
            this.mode = query.mode
        }

        this.initCategoryLists()

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            this.initGameHelpEdit()
        }
    }

    /** E Life Cycle **/
}
