import { render, staticRenderFns } from "./game_help_edit.vue?vue&type=template&id=389d5bbb&scoped=true"
import script from "./game_help_edit.vue?vue&type=script&lang=ts"
export * from "./game_help_edit.vue?vue&type=script&lang=ts"
import style0 from "./game_help_edit.vue?vue&type=style&index=0&id=389d5bbb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389d5bbb",
  null
  
)

export default component.exports